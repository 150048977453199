export default {
  /**
   * 日期转换
   * @param value
   * @param format
   * @returns {string}
   */
  getDate(value, format = '-') {
    let date = value;
    let Y = date.getFullYear() + format;
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + format;
    let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return Y + M + D;
  },

  /**
   *  时间截取
   * @param value
   * @param len
   * @returns {*}
   */
  setInterceptDate(value, len = 10) {
    return value.slice(0, len);
  },

  /**
   *  日期 add day
   * @param date
   * @param day
   */
  setAddDayDate(date, day) {
    let time = date.substring(0, 20);
    time = time.replace(/-/g, '/');
    let timeTamp = new Date(time).getTime();
    let dayTime = day * 86400 * 1000;
    return this.getDate(new Date(timeTamp + dayTime), '-');
  },

  /**
   *  日期计算时间差
   *
   */
  leadTime(time) {
    if (time) {
      let dateBegin = new Date(time.replace(/-/g, '/'));
      let dateEnd = new Date();
      return dateBegin.getTime() - dateEnd.getTime();
    }
  },

  /*
   * 天数计算相差
   * */
  getOffsetDays(time) {
    let dateBegin = new Date(time.replace(/-/g, '/'));
    let offsetTime = Math.abs(Date.now() - dateBegin);
    return Math.ceil(offsetTime / (3600 * 24 * 1e3));
  },

  /**
   * 获取时间
   * @param datetime 时间戳 [默认当前时间]
   * @param format 返回格式 年月日格式
   * @returns {string}
   */
  getDataTimeAt(datetime = new Date().getTime() / 1000, format = '-') {
    let date = new Date(datetime * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + format;
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + format;
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    return Y + M + D + h + m + s;
  },

  /**
   * 获取当前时间 年-月-日 时：分：秒
   *
   * @returns {string}
   */
  getNowFormatDate() {
    let date = new Date();
    let seperator1 = '-';
    let seperator2 = ':';
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    let strDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    let currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + date.getHours() + seperator2 + date.getMinutes() + seperator2 + date.getSeconds();

    return currentdate;
  },

  /**
   * 与当前时间比对
   *
   * @param d1
   * @param d2
   * @returns {boolean}
   * @constructor
   */
  CompareDate(d1, d2) {
    return new Date(d1.replace(/-/g, '/')) > new Date(d2.replace(/-/g, '/'));
  },

  /**
   * 秒转成时分秒
   *
   * @param value
   * @returns {string}
   */
  Seconds(value) {
    var theTime = parseInt(value); // 秒
    var theTime1 = 0; // 分
    var theTime2 = 0; // 小时
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
      }
    }

    var result = '' + parseInt(theTime); //秒
    if (10 > theTime > 0) {
      result = '0' + parseInt(theTime); //秒
    } else {
      result = '' + parseInt(theTime); //秒
    }

    if (10 > theTime1 > 0) {
      result = '0' + parseInt(theTime1) + ':' + result; //分，不足两位数，首位补充0，
    } else {
      result = '' + parseInt(theTime1) + ':' + result; //分
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2) + ':' + result; //时
    }
    return result;
  },
  /**
   * 秒转成时分
   *
   * @param value
   * @returns {string}
   */
  formatSeconds(value) {
    let theTime = parseInt(value); // 秒
    let middle = 0; // 分
    let hour = 0; // 小时
    if (theTime > 60) {
      middle = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (middle > 60) {
        hour = parseInt(middle / 60);
        middle = parseInt(middle % 60);
      }
    }
    let result = '';
    if (theTime > 0) {
      result = '' + parseInt(theTime) + '秒';
    }
    if (middle > 0) {
      result = '' + parseInt(middle) + '分' + result;
    }
    if (hour > 0) {
      result = '' + parseInt(hour) + '小时' + result;
    }
    return result;
  },

  /**
   * 秒转成时分 带冒号的
   *
   * @param value
   * @returns {string}
   */
  formatSecond(value) {
    let theTime = parseInt(value); // 秒
    let middle = 0; // 分
    let hour = 0; // 小时
    if (theTime > 60) {
      middle = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (middle > 60) {
        hour = parseInt(middle / 60);
        middle = parseInt(middle % 60);
      }
    }
    let result = '' + parseInt(theTime) + '';
    if (middle > 0) {
      result = '' + parseInt(middle) + ':' + result;
    }
    if (hour > 0) {
      result = '' + parseInt(hour) + ':' + result;
    }
    return result;
  },
  /*
   * yy-mm-dd转成年月日
   * 传参 格式yy-mm-dd
   * */
  dateTransition(str) {
    var reg = /(\d{4})\-(\d{2})\-(\d{2})/;
    return str.replace(reg, '$1年$2月$3日');
  },
  /*
    /**
     * 时间去除 秒数 保留到分钟
     * @param {时间字符串} str
     */
  dateFormatMinutes(str) {
    if (str && str != '') {
      var reg = /(\d{4})\-(\d{2})\-(\d{2})\ (\d{2})\:(\d{2}):(\d{2})/;
      return str.replace(reg, '$1-$2-$3 $4:$5');
    } else {
      return '';
    }
  },
  /*
   * 时间戳转  日期
   * YY_MM_DD
   * */
  formatDate(date) {
    var date = new Date(date);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    //return YY + MM + DD +" "+hh + mm + ss;
    return YY + MM + DD;
  },

  /*获取当前年月-上传文件所用*/
  getTime() {
    var date = new Date();
    var yarn = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    return yarn.toString() + '' + month.toString();
  },
};
