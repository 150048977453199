import router from './index';
import cache from '../utils/cache';
import user from '../utils/user';
import store from '../store/store';
import util from '../utils/util';

router.beforeEach((to, from, next) => {
  if (to.name === '*.error') {
    store.dispatch('A_ERROR_INFO', {
      code_num: 404,
      error_text: '抱歉，您访问的页面不存在',
    });
  }
  if (util.browserRedirect() && (to.name == 'root' || to.name == 'home')) {
    if (window.location.href.indexOf('www.') != -1) {
      window.location.href = window.location.href.replace('www.', 'm.');
    } else {
      next();
    }
  }
  if (cache.getIsLogin()) {
    if (to.name == 'login' || to.name == 'register' || to.name == 'forget' || to.name == 'agreement' || to.name == 'root') {
      next('/home');
    } else {
      if (to.meta.userInfo) {
        user.getUserInfo();
      }
      if (!localStorage.getItem('circle-uuid') && to.name != 'perfect' && to.name != 'verify') {
        next('/perfect');
      } else {
        next();
      }
      if ((to.meta.userInfo || !store.getters.userInfo.id) && localStorage.getItem('circle-uuid') && !to.meta.share) {
        user.getUserInfo();
      }
    }
  } else {
    if (to.meta.Auth) {
      if (!cache.getIsLogin()) {
        next('/login');
      } else {
        if ((to.meta.userInfo || !store.getters.userInfo.id) && localStorage.getItem('circle-uuid') && !to.meta.share) {
          user.getUserInfo();
        }
        next();
      }
      if (from.name == 'perfect') {
        if (to.name == 'audit') {
          next();
        } else {
          if (store.getters.userInfo.managed_circle.status == 0) {
            next('/perfect');
          } else {
            next();
          }
        }
      }
    } else {
      next();
    }
  }
});
