module.exports = {
  Courses: 'courses', // 课程列表
  ChangeTop: 'change_top', // 设置置顶
  tagsCircle: 'tags/circle', // 获取标签
  DiscoverContents: 'discover/contents', //  创作列表
  Lessons: 'lessons', // 获取章节
  Medias: 'medias', // 获取课程媒体
  Materials: 'materials', // 资料列表
  Comments: 'comments', // 互动
  Delete: 'delete', // 删除
  changeIsTop: '/change_is_top', //  创作置顶
  contentStatus: '/change_content_status', //  设置评论
  changeStatus: '/change_status', //  修改状态
  lives: 'lives', //回播列表
  comments: 'comments', //评论列表
  children: 'children', //评论回复列表
  changeContentStatus: 'change_content_status', // 设置课程评价状态
  goodUsers: 'good_users', //点赞人数
  contents: '/discover/contents',
  batch_destroy: 'medias/batch_destroy', // 批量删除
  lessonsDrag: 'lessons/drag', // 章节拖拽
  medias: 'medias', //视频列表
  liveVideos: '/live_videos',
  categoryStatistics: 'discover/contents/category_statistics',
  commentsCategoryStatistics: 'comments/category_statistics',
  changeAdvance: '/change_advance',
  info: 'info/api_index', // 文章列表
  infoAdd: 'info', // 资料新增
  discoverInfos: 'discover/infos/', // 查看次数
  download: 'download', // 查看次数
  source: 'image/images', // 素材
  change_status: 'image/change_status', // 素材
  batchOperation: 'image/batch_operation', // 批量操作
  categories: 'image/count_by_categories', // 批量操作
  authors: 'circles/authors', // 批量操作,
  contentsAdmin: 'discover/contents/admin', // 待审核列表,
  examine: 'examine', // 审核,
  catalogues: 'catalogues', // 文件列表/新增,
  batchCatalogues: 'catalogues/batch_operation', // 文件列表/新增,
  batchContent: 'catalogues/batch/content', // 下载列表
  exportList: 'statistics/export', // 导出列表
  uploadVideo: 'upload/video', // 上传视频
  uploadAttachment: 'upload/attachment', // 上传图片文稿
  conversion: 'common/short_link/conversion', // 短连接转换
  checkDown: 'catalogues/check_down', // 获取下载结果
  versionInfo: 'common/client/version_info', // 客户端版本
};
