<template>
  <div class="ueditor-wrap">
    <vue-ueditor-wrap v-model="content" @ready="ready" :config="config" @beforeInit="addCustomButtom" :editor-dependencies="editorDependencies"></vue-ueditor-wrap>
    <upload-file
      ref="uploadFile"
      :accept_type="'image/png, image/jpeg, image/jpg,image/gif'"
      @successUrl="imageSuccessCBK"
      :scene_type="'editor'"
      :limit="5"
      :multiple="true"
      :folder="folder"
    ></upload-file>
    <!--  :editor-dependencies="editorDependencies"  -->
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
import uploadFile from './uploadFile';

export default {
  name: 'Ueditor',
  props: {
    folder: {
      type: [String],
      default: 'default',
    },
  },
  data() {
    return {
      content: '',
      config: {
        UEDITOR_HOME_URL: '/static/UEditor/',
        // serverUrl: 'https://adminseed.youlika.net/component/uploads/index',
        // catchRemoteImageEnable: true,
        initialFrameHeight: 380,
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        maximumWords: 100000000,
        zIndex: 0,
        toolbars: [
          [
            'fullscreen',
            'source',
            '|',
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'fontborder',
            'strikethrough',
            'superscript',
            'subscript',
            'removeformat',
            'formatmatch',
            'autotypeset',
            'blockquote',
            'pasteplain',
            '|',
            'forecolor',
            'backcolor',
            'insertorderedlist',
            'insertunorderedlist',
            'selectall',
            'cleardoc',
            '|',
            'rowspacingtop',
            'rowspacingbottom',
            'lineheight',
            '|',
            'customstyle',
            'paragraph',
            'fontsize',
            '|',
            'directionalityltr',
            'directionalityrtl',
            'indent',
            '|',
            'justifyleft',
            'justifycenter',
            'justifyright',
            'justifyjustify',
            '|',
            'touppercase',
            'tolowercase',
            '|',
            'anchor',
            '|',
            'imagenone',
            'imageleft',
            'imageright',
            'imagecenter',
            '|',
            'emotion',
            'horizontal',
            'date',
            'time',
            '|',
            'searchreplace',
          ],
        ],
        fontsize: [10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 30, 36],
      },
      editorInstance: null,
      editorDependencies: [],
    };
  },
  created() {
    /*'inserttable', 'deletetable', 'insertparagraphbeforetable','insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols',  */
    /* this.editorDependencies = [
               'ueditor.config.js',
               'ueditor.all.min.js',
               // 添加秀米相关的资源
               'xiumi/xiumi-ue-dialog-v5.js',
               'xiumi/xiumi-ue-v5.css',
             ];*/
    this.editorDependencies = ['ueditor.config.js', 'ueditor.all.js'];
  },
  mounted() {
    this.$nextTick(function() {
      setTimeout(() => {
        if (this.$parent.fieldValue) {
          this.content = this.$parent.fieldValue;
        }
      }, 1000);
    });
  },
  watch: {
    content() {
      this.$emit('input', this.content);
    },
  },
  methods: {
    // 初始赋值
    ready(editorInstance) {
      this.editorInstance = editorInstance;
    },
    // 新增按钮
    addCustomButtom() {
      let _this = this;
      window.UE.registerUI('image-button', (editor, uiName) => {
        var btn = new window.UE.ui.Button({
          //按钮的名字
          name: uiName,
          //提示
          title: '上传图片',
          //添加额外样式，指定icon图标，这里默认使用一个重复的icon
          cssRules: 'background-position: -726px -77px;',
          //点击时执行的命令
          onclick: function() {
            _this.$refs.uploadFile.fileUploadDialog = true;
          },
        });
        return btn;
      });
    },
    // 图片上传完回调
    imageSuccessCBK(arr) {
      arr.forEach((v) => this.editorInstance.execCommand('inserthtml', `<img class="wscnph" style="max-width: 100%" src="${v}" >`));
    },
  },
  components: {
    VueUeditorWrap,
    uploadFile,
  },
};
</script>

<style lang="scss" scoped>
:deep(.edui-editor) {
  // z-index: 100 !important;
}
.ueditor-wrap {
  position: relative;
  height: 100%;
  :deep(.edui-default .edui-editor-toolbarbox) {
    box-shadow: none;
  }

  :deep(.edui-default .edui-editor-toolbarboxouter) {
    /*border-bottom: none;
      box-shadow: none;*/
    box-shadow: none;
    background-color: rgba(246, 248, 249, 1);
    background-image: none;
  }
}
</style>
