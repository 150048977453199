import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import router from './router/index';
import store from './store/store';
import api from './http/api';
import http from './http/request';
import cache from './utils/cache';
import carbon from './utils/carbon';
import user from './utils/user';
import staticResource from './utils/static';
import util from './utils/util';
import Ueditor from 'components/Ueditor';
import handle from './utils/handle';
import astrict from './utils/astrict.js';
import exportExcel from './utils/outToExcel';
import ossClient from './utils/ossClient';
import websock from './utils/websocket';
Vue.use(astrict);
Vue.component('Ueditor', Ueditor);

import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/element-variables.scss';

Vue.config.productionTip = false;
import './assets/less/public/public.less';

Vue.use(ElementUI);
Vue.prototype.$exportExcel = exportExcel;
Vue.prototype.$api = api;
Vue.prototype.$cache = cache;
Vue.prototype.$http = http;
Vue.prototype.$user = user;
Vue.prototype.$carbon = carbon;
Vue.prototype.$static = staticResource;
Vue.prototype.$util = util;
Vue.prototype.$handle = handle;
Vue.prototype.$ossClient = ossClient;
Vue.prototype.$websock = websock;
// 登录信息跳转
import './router/util';
// 请求拦截
import './http/axios';

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
