export default [
  // 我的资料
  {
    path: '/user',
    name: 'user',
    component: () => import('view/user/user/Index'),
    redirect: '/user/user',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '用户管理',
      userInfo: false,
    },
    children: [
      // 课时管理
      {
        path: 'user',
        name: 'user.user',
        component: () => import('view/user/user/info/Users'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '用户管理',
          userInfo: false,
        },
      },
      // 用户信息
      {
        path: 'show/:uuid',
        name: 'user.show',
        component: () => import('view/user/user/info/Show'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '查看用户',
          userInfo: false,
        },
      },
      // 用户交易
      {
        path: 'account/:uuid',
        name: 'user.account',
        component: () => import('view/user/user/info/Account'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '用户交易',
          userInfo: false,
        },
      },
    ],
  },
  {
    path: '/balance',
    name: 'balance',
    component: () => import('view/user/balance/Index'),
    redirect: '/balance/list',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '我的余额',
      userInfo: false,
    },
    children: [
      // 课时管理
      {
        path: 'list',
        name: 'balance.List',
        component: () => import('view/user/balance/balanceList/List'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '我的余额',
          userInfo: false,
        },
      },
      {
        path: 'Pay',
        name: 'balance.Pay',
        component: () => import('view/user/balance/balanceList/Pay'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '充值',
          userInfo: true,
        },
      },
    ],
  },
];
