import axios from 'axios';
import router from '@/router';
import { Message } from 'element-ui';
import store from '../store/store';
import cache from '../utils/cache';
axios.interceptors.request.use(
  (config) => {
    if (config.url === 'https://helixlife-image.oss-cn-beijing.aliyuncs.com') {
      return config;
    }
    config.url = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PREFIX + '/' + process.env.VUE_APP_API_VERSION + '/' + config.url;
    return config;
  },
  (error) => {},
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (router.history.current.name === 'login' || router.history.current.name === 'register' || router.history.current.name === 'forget') {
        return Promise.reject(error);
      } else {
        cache.removeLoginInfo();
        return router.replace('/login');
      }
    } else if (error.response.status === 422) {
      Message.error(error.response.data.message);
      return Promise.reject(error);
    } else if (error.response.status === 403) {
      Message.error(error.response.data.message);
      return Promise.reject(error);
    } else if (error.response.status === 475) {
      return Promise.reject(error);
    } else {
      let msg = '未找到，您所请求的资源';
      switch (error.response.status) {
        case 429:
          msg = '你操作太频繁了';
          break;
        case 500:
          msg = '服务器内部错误';
          break;
        case 503:
          msg = '服务维护中';
          break;
        case 404:
          msg = '你所访问的页面不存在';
          break;
        default:
          msg = '出现了意外';
          break;
      }
      store.dispatch('A_ERROR_INFO', {
        code_num: error.response.status,
        error_text: msg,
      });
      setTimeout(() => {
        return router.push('/error');
      }, 200);
    }
  },
);
