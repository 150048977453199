<template>
  <div class="footer-wrap align-center">
    <p class="footer-text vertical-center">
      {{ $static.footer_text }}<a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="less" scoped>
.footer-wrap {
  padding: 20px 0;
  .footer-text {
    font-size: 12px;
    font-weight: 400;
    color: #93999f;
    line-height: 1;
    .public-icon {
      margin-left: 12px;
      margin-right: 6px;
    }
    a {
      color: #93999f;
      text-decoration: none;
    }
    .records-link {
      margin-left: 6px;
    }
  }
}
</style>
