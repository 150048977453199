export default [
  // 标签管理
  {
    path: '/sharefiles',
    name: 'sharefiles',
    component: () => import('view/shareFile/Index'),
    meta: {
      share: true,
      title: '文件分享',
      userInfo: false,
    },
  },
];
