<template>
  <div class="frame">
    <div class="poster align-center" @click="yq()" v-if="showShare">
      <img src="~assets/images/index/circle.png" alt="" class="poster-img" />
      <img src="~assets/images/index/circle-h.png" alt="" class="poster-img-h" />
      <span class="postertext">{{ field }}邀请</span>
    </div>
    <div class="wx align-center">
      <img src="~assets/images/auth/wx.png" alt="" class="wx-img wximg" />
      <img src="~assets/images/auth/wx-h.png" alt="" class="wx-img wximg-h" />
      <span class="wxtext">微信客服</span>

      <div class="left">
        <img :src="code_url != '' ? code_url : 'https://static.helixlife.cn/circle/images/broadside-server.png'" alt="" class="leftimg" />
        <p class="code-hint">扫描二维码，添加我的企业微信</p>
      </div>
    </div>
    <el-dialog :visible.sync="dialog" width="375px" custom-class="preview-dialog" v-if="dialog">
      <div class="preview-code">
        <div class="circle">
          <div class="circle-img">
            <img :src="img" id="dialogimg" alt="" />
          </div>
          <div class="circle-text">
            <div class="circle-name">{{ userInfo.managed_circle.name }}</div>
            <div class="circle-info white-space-2">{{ userInfo.managed_circle.desc }}</div>
          </div>
        </div>
        <!--        <div id="qrcode"></div>-->
        <div class="img_box" v-loading="qr_code_loading">
          <vue-qr id="qrcode" :margin="0" :text="circle_url" :size="200"></vue-qr>
        </div>

        <div class="hint-p">扫描二维码加入{{ field }}</div>
        <div class="reset" @click="reset()">
          <img src="~assets/images/index/reset.png" alt="" class="reset-img" />
          <img src="~assets/images/index/reset-h.png" alt="" class="reset-img-h" />
          刷新
        </div>
        <div class="lj-btn" id="copy">复制链接</div>
        <div class="hb-btn" @click="download()">下载海报</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import QRCode from "qrcodejs2";
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';
export default {
  name: 'wx',
  data() {
    return {
      code_url: '',
      img: '',
      dialog: false,
      circle_url: '',
      field: '',
      code_time: '',
      userInfo: {},
      showDiog: true,
      qr_code_loading: false,
      // share_count: '',
      showShare: false,
    };
  },
  created() {
    this.getCode();
    this.field = process.env.VUE_APP_FIELD;
  },
  components: {
    VueQr,
  },

  computed: {
    userInfos() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    userInfos(newValue, oldVal) {
      console.log(newValue, '-----------------');
      if (newValue.is_admin_middleground || (!newValue.is_admin_middleground && newValue?.managed_circle?.is_public)) {
        this.showShare = true;
        console.log(this.showShare);
      } else {
        this.showShare = false;
      }
    },
  },

  methods: {
    getInfo() {
      this.userInfo = this.$store.getters.userInfo;
      console.log(this.userInfo.managed_circle.invitation_code_time, '=======================================');
      if (this.userInfo.managed_circle.invitation_code_time === 0) {
        this.code_time = 315360000000;
      } else {
        if (this.userInfo.managed_circle.unit === 1) {
          this.code_time = 1000 * 60 * 60 * 24 * 7 * this.userInfo.managed_circle.invitation_code_time;
        } else if (this.userInfo.managed_circle.unit === 2) {
          this.code_time = 1000 * 60 * 60 * 24 * this.userInfo.managed_circle.invitation_code_time;
        } else if (this.userInfo.managed_circle.unit === 3) {
          this.code_time = 1000 * 60 * 60 * this.userInfo.managed_circle.invitation_code_time;
        } else if (this.userInfo.managed_circle.unit === 4) {
          this.code_time = 1000 * 60 * this.userInfo.managed_circle.invitation_code_time;
        } else {
          this.code_time = 1000 * this.userInfo.managed_circle.invitation_code_time;
        }
      }

      this.circle_url = `${process.env.VUE_APP_M_LINK}/invitation/channel?invitation_time=${new Date().getTime() + this.code_time}&type=invitation&code=${
        this.userInfo.invitation_code
      }&circle_code=${this.userInfo.managed_circle.invitation_code}&uname=${encodeURIComponent(this.userInfo.nickname)}&qname=${encodeURIComponent(this.userInfo.managed_circle.name)}`;
      // }
      // console.log(this.circle_url);
      // this.qr_code_loading = false;
      // });
    },
    // 获取二维码
    getCode() {
      let url = this.$api.commonConfigs;
      this.$http.get(url).then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          let data = res.data.data;
          if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].key === 'contact' && data[i].value && data[i].value.qr_code) {
                this.code_url = data[i].value.qr_code;
                this.$store.dispatch('A_SERVER_CODE', data[i].value.qr_code);
              }
            }
          }
        }
      });
    },
    //刷新二维码
    reset() {
      this.circle_url = `${process.env.VUE_APP_M_LINK}/invitation/channel?invitation_time=${new Date().getTime() + this.code_time}&type=invitation&code=${
        this.userInfo.invitation_code
      }&circle_code=${this.userInfo.managed_circle.invitation_code}&uname=${encodeURIComponent(this.userInfo.nickname)}&qname=${encodeURIComponent(this.userInfo.managed_circle.name)}`;
      console.log(this.circle_url);
    },
    //邀请弹窗
    yq() {
      this.getInfo();
      if (this.userInfo.managed_circle) {
        this.getBase64(this.userInfo.managed_circle.icon, (dataURL) => {
          this.img = dataURL;
        });
        this.dialog = true;
        setTimeout(() => {
          this.copy();
        }, 200);
      } else {
        return;
      }
    },
    //下载海报
    download() {
      html2canvas(document.querySelector('.preview-dialog'), { allowTaint: true, useCORS: true }).then((canvas) => {
        const imgUrl = canvas.toDataURL('image/jpeg');
        const a = document.createElement('a');
        a.href = imgUrl;
        a.download = 'invitation';
        a.click();
      });
    },
    //复制成功
    copy() {
      const btn = document.querySelector('#copy');
      btn.addEventListener('click', () => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', this.circle_url);
        input.select();
        // if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success('复制成功');
        // }
        document.body.removeChild(input);
      });
    },
    //海报内图片转base64
    getBase64(url, callback) {
      let Img = new Image(),
        dataURL = '';
      Img.src = url + '?v=' + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
      Img.setAttribute('crossOrigin', 'Anonymous'); // 解决控制台跨域报错的问题（没用就跟后台沟通添加跨域）
      Img.onload = function () {
        //要先确保图片完整获取到，这是个异步事件
        let canvas = document.createElement('canvas'), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/'); //转换图片为dataURL
        callback ? callback(dataURL) : null; //调用回调函数
      };
    },
  },
};
</script>

<style lang="less" scoped>
.poster {
  position: fixed;
  right: 30px;
  top: calc(50% - 96px);
  width: 64px;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
  border-radius: 6px 6px 0 0;
  flex-direction: column;
  z-index: 2;
  cursor: pointer;
  .postertext {
    font-size: 12px;
    font-weight: 400;
    color: #93999f;
    margin-top: 5px;
  }
  .poster-img {
    width: 24px;
    height: 24px;
  }
  .poster-img-h {
    width: 24px;
    height: 24px;
    display: none;
  }
}
.poster:hover {
  .postertext {
    color: @theme;
  }
  .poster-img {
    display: none;
  }
  .poster-img-h {
    display: block;
  }
}
/deep/.preview-dialog {
  width: 375px;
  height: 600px;
  background-image: url('../assets/images/index/hb.png');
  background-size: cover;
  border-radius: 10px;
  box-shadow: unset;
  .el-icon-close {
    color: #ffffff !important;
  }
}
.preview-code {
  z-index: 9999;
  position: relative;
  .circle {
    width: 235px;
    height: 60px;
    position: absolute;
    top: 75px;
    left: 55px;
    display: flex;
    justify-content: space-between;
    .circle-img {
      img {
        width: 60px;
        height: 60px;
      }
    }
    .circle-text {
      flex: 1;
      margin-left: 20px;
      .circle-name {
        font-size: 14px;
        font-weight: bold;
        color: #313a43;
      }
      .circle-info {
        font-size: 12px;
        font-weight: 400;
        color: #93999f;
        line-height: 20px;
      }
    }
  }
  .img_box {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 75px;
    #qrcode {
      width: 100%;
      height: 100%;
    }
  }

  .hint-p {
    font-size: 14px;
    color: #93999f;
    text-align: center;
    position: absolute;
    top: 375px;
    left: 75px;
    width: 180px;
    text-align: center;
  }
  .reset {
    position: absolute;
    top: 395px;
    right: 30px;
    color: #93999f;
    cursor: pointer;
    .reset-img {
      width: 14px;
      height: 12px;
    }
    .reset-img-h {
      width: 14px;
      height: 12px;
      display: none;
    }
  }
  .reset:hover {
    color: #71777d;
    .reset-img {
      display: none;
    }
    .reset-img-h {
      display: inline-block;
    }
  }
  .lj-btn {
    width: 140px;
    height: 42px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 21px;
    font-size: 16px;
    font-weight: 400;
    color: #81d8d0;
    text-align: center;
    line-height: 42px;
    position: absolute;
    top: 465px;
    left: 20px;
    cursor: pointer;
  }
  .lj-btn:hover {
    background: rgba(255, 255, 255, 1);
  }
  .hb-btn {
    width: 140px;
    height: 42px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 21px;
    font-size: 16px;
    font-weight: 400;
    color: #81d8d0;
    text-align: center;
    line-height: 42px;
    position: absolute;
    top: 465px;
    right: 20px;
    cursor: pointer;
  }
  .hb-btn:hover {
    background: rgba(255, 255, 255, 1);
  }
}
.frame {
  .wx {
    position: fixed;
    right: 30px;
    top: calc(50% - 32px);
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0 0 6px 6px;
    flex-direction: column;
    z-index: 2;
    cursor: pointer;
    .wxtext {
      font-size: 12px;
      font-weight: 400;
      color: #93999f;
      margin-top: 5px;
    }
    .wx-img {
      width: 29px;
      height: 24px;
    }
    .wximg-h {
      display: none;
    }
    .left {
      display: none;
      position: absolute;
      left: -160px;
      width: 140px;
      background: #ffffff;
      border-radius: 6px;
      padding: 10px;
      box-sizing: border-box;
      .leftimg {
        width: 120px;
        height: 120px;
      }
    }
    .left:after {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 10px solid #ffffff;
      border-bottom: 6px solid transparent;
      position: absolute;
      right: -10px;
      top: 50%;
      margin-top: -6px;
      z-index: 1;
    }
    .code-hint {
      font-size: 12px;
      color: #93999f;
      text-align: center;
    }
  }
  .wx:hover {
    .wxtext {
      color: @theme;
    }
    .wximg-h {
      display: inline-block;
    }
    .wximg {
      display: none;
    }
    .left {
      display: block;
    }
  }
}
</style>
