import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user'
import error from "./module/error";
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        error
    }
})
