export default [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('view/auth/Login'),
    meta: {
      Auth: false,
      title: '登录',
      userInfo: false,
    },
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('view/auth/Register'),
    meta: {
      Auth: false,
      title: '注册',
      userInfo: false,
    },
  },
  // 忘记
  {
    path: '/forget',
    name: 'forget',
    component: () => import('view/auth/Forget'),
    meta: {
      Auth: false,
      title: '忘记密码',
      userInfo: false,
    },
  },
  // 完善信息
  {
    path: '/perfect',
    name: 'perfect',
    component: () => import('view/auth/Perfect'),
    meta: {
      Auth: true,
      header: false,
      nav: false,
      title: '完善信息',
      // userInfo: true,
    },
  },
  // 审核中
  {
    path: '/audit',
    name: 'audit',
    component: () => import('view/auth/Audit'),
    meta: {
      Auth: true,
      header: false,
      nav: false,
      title: '审核中',
      userInfo: true,
    },
  },
  // 注册协议
  {
    path: '/auth/agreement',
    name: 'agreement',
    component: () => import('view/auth/Agreement'),
    meta: {
      Auth: false,
      header: false,
      nav: false,
      title: '协议',
    },
  },
  // 隐私协议
  {
    path: '/auth/privacy',
    name: 'privacy',
    component: () => import('view/auth/Privacy'),
    meta: {
      Auth: false,
      header: false,
      nav: false,
      title: '协议',
    },
  },
  // 页面报错
  // 500
  {
    path: '/error',
    name: 'error',
    component: () => import('view/error/Error'),
    meta: {
      Auth: false,
      title: '出错了！',
      userInfo: false,
    },
  },
  {
    path: '*',
    name: '*.error',
    component: () => import('view/error/Error'),
    meta: {
      Auth: false,
      title: '出错了！',
      userInfo: false,
    },
  },
  // 选择小组
  {
    path: '/verify',
    name: 'verify',
    component: () => import('view/auth/Verify'),
    meta: {
      Auth: true,
      header: false,
      nav: false,
      title: '选择小组',
    },
  },
  // 登录过期
  {
    path: '/overdue',
    name: 'overdue',
    component: () => import('view/auth/Overdue'),
    meta: {
      Auth: false,
      header: false,
      nav: false,
      title: '登录过期',
    },
  },
  {
    path: '/expiredpassword',
    name: 'expiredpassword',
    component: () => import('view/auth/ExpiredPassword'),
    meta: {
      Auth: false,
      header: false,
      nav: false,
      title: '登录过期',
    },
  },
];
