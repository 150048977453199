import http from '../http/request';
import Api from '../http/api';
import store from '../store/store';
import router from '../router/index';
import cache from './cache';

export default {
  /*
   * 获取用户信息
   * */
  getUserInfo() {
    store.dispatch('A_USER_INFO', {});
    http
      .get(Api.userInfo, true)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem('is_bind_bank', res.data.data.is_bind_bank);
          localStorage.setItem('is_approval', res.data.data.is_approval);
          localStorage.setItem('is_admin_middleground', res.data.data.is_admin_middleground);
          localStorage.setItem('is_has_benefit_circle', res.data.data.is_has_benefit_circle);
          localStorage.setItem('user_id', res.data.data.id);
          localStorage.setItem('circle_id', res.data.data.managed_circle.id);
          // localStorage.setItem('is_circle_author', res.data.data.is_circle_author);
          store.dispatch('A_USER_INFO', res.data.data);
          let user_info = res.data.data;
          if (user_info.managed_circle == null || user_info.managed_circle.info == null) {
            if (router.history.current.name === 'perfect') {
            } else {
              // router.replace({ path: '/perfect' });
              // return false;
            }
          } else {
            if (user_info.managed_circle.status === 0 || user_info.managed_circle.status === -1) {
              if (router.history.current.name === 'perfect') {
              } else {
                router.replace({ path: '/audit' });
                return false;
              }
            }
          }
          let route_name = router.history.current.name;
          if (route_name === 'login' || route_name === 'register' || route_name === 'forget' || route_name === 'verify') {
            if (user_info.managed_circle.status === -1 || user_info.managed_circle.status === 0) {
              router.replace({ path: '/audit' });
            } else {
              router.replace({ path: '/home' });
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          cache.removeLoginInfo();
          router.replace({ path: '/login' });
        }
      });
  },
};
