const ls = localStorage;
import handle from './handle';

export default {
  // 判断是否登录
  getIsLogin() {
    if (ls.getItem('token_type') && ls.getItem('access_token')) {
      let expires_in = ls.getItem('expires_in');
      /* if (new Date().getTime() >= expires_in) {
                // this.removeLoginInfo();
                return false;
            } else {
                return true;
            }*/
      return true;
    } else {
      return false;
    }
  },
  // 获取登录信息
  getAuthorization() {
    let data = [];
    let header = {};
    if (typeof ls.getItem('token_type') === 'undefined' || typeof ls.getItem('access_token') === 'undefined') {
      window.location.href =
        '/auth/login' +
        '?from=' +
        handle.urlEncode(
          JSON.stringify({
            url: window.location.pathname + window.location.search,
          }),
        );
      return;
    }
    if (ls.getItem('token_type') === '' || ls.getItem('access_token') === '') {
      window.location.href =
        '/auth/login' +
        '?from=' +
        handle.urlEncode(
          JSON.stringify({
            url: window.location.pathname + window.location.search,
          }),
        );
      return;
    }
    data['tokenType'] = ls.getItem('token_type');
    data['accessToken'] = ls.getItem('access_token');
    header.Authorization = data['tokenType'] + ' ' + data['accessToken'];
    // header.source = 'web';
    if (localStorage.getItem('is_admin_middleground') === 'false' && data['tokenType']) {
      header['author'] = 'is_author';
    }
    if (localStorage.getItem('circle-uuid')) {
      header['circle-uuid'] = localStorage.getItem('circle-uuid');
    }
    if (data['tokenType'] && data['accessToken']) {
      return header;
    }
  },
  // 设置登录信息
  setLoginInfo(data) {
    if (data.access_token && data.token_type && data.refresh_token) {
      ls.setItem('access_token', data.access_token);
      ls.setItem('token_type', data.token_type);
      ls.setItem('refresh_token', data.refresh_token);
    }
  },
  // 设置登录刷新token时间
  setExpiresIn(time) {
    let timestamp = new Date().getTime();
    ls.setItem('expires_in', timestamp + time * 1000);
  },
  // 清除缓存
  removeLoginInfo(clean = true) {
    ls.removeItem('access_token');
    ls.removeItem('token_type');
    ls.removeItem('refresh_token');
    ls.removeItem('wechat_id');
    ls.removeItem('expires_in');
    ls.removeItem('openId');
    ls.removeItem('userInfo');
    ls.removeItem('circle-uuid');
  },
};
