export default [
  // 用户分析
  {
    path: '/analysis/user',
    name: 'analysis.user',
    component: () => import('view/data-analysis/user/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '用户分析',
      userInfo: false,
    },
  },
  // 内容分析
  {
    path: '/analysis/content',
    name: 'analysis.content',
    component: () => import('view/data-analysis/content/Index'),
    redirect: '/analysis/content/indexAll',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '内容分析',
      userInfo: false,
    },
    children: [
      {
        path: 'indexAll',
        name: 'analysis.content.indexAll',
        component: () => import('view/data-analysis/content/indexAll'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '内容分析',
        },
      },
      {
        path: 'detaile',
        name: 'analysis.content.detaile',
        component: () => import('view/data-analysis/content/detaile'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '文章/视频分析',
        },
      },
    ],
  },

  // 课程分析
  {
    path: '/analysis/course',
    name: 'analysis.course',
    component: () => import('view/data-analysis/course/Index'),
    redirect: '/analysis/course/course',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '课程分析',
      userInfo: false,
    },
    children: [
      {
        path: 'course',
        name: 'analysis.courses',
        component: () => import('view/data-analysis/course/views/Index'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '课程分析',
        },
      },
      {
        path: 'detaile',
        name: 'analysis.course.detaile',
        component: () => import('view/data-analysis/course/views/detaile'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '课程分析',
        },
      },
    ],
  },
  // 课程分析
  {
    path: '/analysis/live',
    name: 'analysis.live',
    component: () => import('view/data-analysis/live/Index'),
    redirect: '/analysis/live/live',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '课程分析',
      userInfo: false,
    },
    children: [
      {
        path: 'live',
        name: 'analysis.lives',
        component: () => import('view/data-analysis/live/views/Index'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '直播分析',
        },
      },
      {
        path: 'detaile',
        name: 'analysis.live.detaile',
        component: () => import('view/data-analysis/live/views/detaile'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '直播分析',
        },
      },
    ],
  },
  // 金币分析
  {
    path: '/analysis/gold',
    name: 'analysis.gold',
    component: () => import('view/data-analysis/gold/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '金币分析',
      userInfo: false,
    },
  },
];
