export default [
  // 首页
  {
    path: '/',
    name: 'root',
    component: () => import('view/work/home/Index'),
    meta: {
      Auth: false,
      header: false,
      nav: false,
      title: '首页',
      userInfo: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('view/work/index/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '首页',
      userInfo: true,
    },
  },

  // 我的资料
  {
    path: '/profile',
    name: 'profile',
    component: () => import('view/work/profile/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '我的资料',
      userInfo: false,
    },
  },
  // 规则配置
  {
    path: '/rule',
    name: 'rule',
    component: () => import('view/work/rule/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '规则配置',
      userInfo: false,
    },
  },
  //修改密码
  {
    path: '/password',
    name: 'password',
    component: () => import('view/work/profile/password'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '修改密码',
      userInfo: false,
    },
  },
];
