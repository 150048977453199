import index from './api/index';
import auth from './api/auth';
import content from './api/content';
import data from './api/data';
import user from './api/user';
import work from './api/work';
import statistics from './api/statistics';
import meeting from './api/meeting';
var api = Object.assign(index, auth, content, data, user, work, statistics, meeting);
export default api;
