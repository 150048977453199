<template>
  <div id="app">
    <!--  登录后展示  -->
    <div v-if="$cache.getIsLogin()">
      <div v-if="$route.name != 'root'">
        <!--   && $store.getters.userInfo.id     -->
        <div v-if="$store.getters.userInfo">
          <circles-header v-if="$route.meta.header"></circles-header>
          <div class="circles-wrap space-between">
            <div class="circles-left">
              <circles-nav v-if="$route.meta.nav"></circles-nav>
            </div>
            <div class="circles-right" v-if="!$route.meta.share">
              <router-view></router-view>
            </div>
          </div>
          <circles-footer v-if="$route.meta.nav"></circles-footer>
          <wx v-if="$route.meta.nav"></wx>
        </div>
        <div class="body-loading-wrap" v-loading="true" v-else></div>
      </div>
      <div class="no_userInfo" v-if="!$store.getters.userInfo || $route.meta.share">
        <router-view></router-view>
        <circles-footer v-if="!$route.meta.share"></circles-footer>
      </div>
    </div>
    <!--  登录前展示  -->
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CirclesHeader from 'components/circlesHeader';
import CirclesNav from 'components/circlesNav';
import CirclesFooter from './components/Footer';
import wx from 'components/wx';
export default {
  name: 'App',
  components: {
    CirclesHeader,
    CirclesNav,
    CirclesFooter,
    wx,
  },
};
</script>

<style lang="less" scoped>
.circles-wrap {
  width: 1200px;
  margin: 40px auto 0 auto;
  .circles-right {
    width: 958px;
    margin-bottom: 20px;
    //background: #FFFFFF;
  }
}
.body-loading-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
}
</style>
<style>
.el-divider {
  background-color: #ebeff2 !important;
}
.el-tabs__nav-wrap::after {
  background-color: #ebeff2;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #b7bbbf !important;
}
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #93999f !important;
}

.el-dialog__headerbtn {
  top: 14px !important;
  font-size: 20px !important;
}
.el-table::before {
  height: 0 !important;
}
.el-table__empty-text {
  color: #71777d !important;
}
.el-select-dropdown__item {
  color: #71777d !important;
}
.el-select-dropdown__item.is-disabled {
  color: #c0c4cc !important;
  cursor: not-allowed;
}
.loadmore {
  color: #93999f !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  border: unset;
}
.el-form-item__error {
  color: #f01414 !important;
}
.el-dropdown-menu__item {
  color: #93999f !important;
}
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  background-color: #f6f8f9 !important;
  color: #71777d !important;
}
.el-form-item {
  margin-bottom: 24px !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #008aff !important;
  border-color: #008aff !important;
}
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #008aff !important;
  border-color: #008aff !important;
}
.el-checkbox__input .el-checkbox__inner:hover {
  border-color: #008aff !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #008aff !important;
}
.rule {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
  scrollbar-arrow-color: red;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(183, 187, 191, 0.6);
  scrollbar-arrow-color: red;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #fff;
}

.form-operation .el-button + .el-button {
  margin-left: 20px;
}
.el-picker-panel .el-button--text {
  display: none;
}
.drop .el-dropdown-menu__item {
  min-width: 62px;
}
.no_userInfo {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}
</style>
