import http from '../http/request';
import Api from '../http/api';
import OSS from 'ali-oss';
export default {
	// 下载文件
	ossDownload() {
		let _this= this;
		http.get('oss/sign', {params: {
			is_my_file: 1,
			circle_id: localStorage.getItem('circle_id'),
			user_id: localStorage.getItem('user_id'),
		}}).then((res) => {
        var data = res.data;
        if (data.success) {
           let oss_data= data.data;
		   let Client = new OSS({
		     accessKeyId: oss_data.access_id,
		     accessKeySecret: oss_data.access_key,
		     region: oss_data.region,
		     bucket: oss_data.bucket, //_this.aliyunOssToken.bucket 
		   	 secure: true,
		   });
		   
        } else {
          _self.$message.warning(data.msg);
        }
      });
	}
}