module.exports = {
  Login: 'auth/login',
  userInfo: 'auth/user_info',
  updateUser: 'auth/update_user', //修改用户
  Circles: 'circles',
  Captcha: 'captcha', // 获取图片验证码
  verification: 'verification', // 短信验证码
  Register: 'auth/register', // 登录
  resetPassword: 'auth/reset_password', // 重置
  infos: '/infos', //小组信息
  userManagedCircles: 'auth/user_managed_circles', // 获取小组列表
  platformHost: 'common/platform_host', // 获取H5链接
  realNameAuth: 'user/real_name_auth', // 实名认证结果
  approvals: 'user/approvals', // 职业认证结果
  bankcard: 'user/bankcard', //银行卡结果
  replaceBankcard: 'auth/bind/bankcard', //绑定银行卡
  distinguishBankcard: 'auth/ocr/bankcard', //绑定银行卡
  loginConfigs: 'common/login/configs', //配置信息
  ossSign: 'oss/sign', //配置信息
};
