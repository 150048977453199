export default {
    state: {
        errorInfo: {}    // 用户信息
    },
    mutations: {
        M_ERROR_INFO(state, data) {
            state.errorInfo= data
        }
    },
    actions: {
        A_ERROR_INFO(context, data){
            context.commit('M_ERROR_INFO', data)
        }
    },
    getters: {
        errorInfo: state => {
            return state.errorInfo
        }
    }
}
