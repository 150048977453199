<template>
  <div class="circles-header-wrap">
    <div class="circles-header-cont space-between">
      <div class="header-cont-left vertical-center">
        <img src="~assets/images/public/logo.png" class="header-logo" alt="" />
        <p class="hint-p">医生为本，取之于医，用之于医</p>
      </div>
      <div class="header-cont-right vertical-center">
        <p class="login-time">登录时间：{{ userInfo.last_login_at }}</p>
        <div class="gold vertical-center" v-if="userInfo.is_admin_middleground">
          <img src="~assets/images/index/gold.png" class="goldimg" alt="" />
          <p>当前金币：{{ userInfo.managed_circle && userInfo.managed_circle.account && userInfo.managed_circle.account.gold ? userInfo.managed_circle.account.gold : 0 }}</p>
        </div>
        <div class="recharge align-center" v-if="userInfo.is_admin_middleground">
          <span @click="toPay">去充值</span>
          <div class="service">
            <img :src="serverCode ? serverCode : 'https://static.helixlife.cn/circle/images/recharge-server.png'" class="serviceimg" alt="" />
            <p>请联系客服人员</p>
          </div>
          <div class="recharge-b"></div>
        </div>

        <div class="header-user-box">
          <div class="user-info-box vertical-center">
            <img :src="userInfo.avatar" class="user-avatar" alt="" />
            <!--          <p class="user-name white-space">{{userInfo.nickname}}</p>-->
          </div>
          <div class="header-user-cont">
            <div class="user-top vertical-center">
              <img :src="userInfo.avatar" class="user-avatar" alt="" />
              <div class="">
                <p class="user-name white-space" :title="userInfo.nickname">{{ userInfo.nickname }}</p>
                <div class="auth auth-h align-center" v-if="userInfo && userInfo.managed_circle && userInfo.managed_circle.status == 1">
                  <img src="~assets/images/header/auth-h.png" alt="" class="authimg" />
                  <span>已认证</span>
                </div>
                <!--              <div class="auth align-center" v-else>-->
                <!--                <img src="~assets/images/header/auth.png" alt="" class="authimg">-->
                <!--                <span >未认证</span>-->
                <!--              </div>-->
              </div>
            </div>
            <div class="set-btn-box">
              <div class="set-btn-item user-set-btn vertical-center" @click="userLink">
                <img src="~assets/images/header/user-icon.png" alt="" class="user-img" />
                <img src="~assets/images/header/user-icon-h.png" alt="" class="user-img-h" />
                <p>我的资料</p>
              </div>
              <div class="set-btn-item password vertical-center" @click="$router.push('/password')">
                <img src="~assets/images/header/password.png" alt="" class="password-img" />
                <img src="~assets/images/header/password-h.png" alt="" class="password-img-h" />
                <p>修改密码</p>
              </div>
              <div class="set-btn-item password vertical-center" v-if="parseInt(userInfo.author_circle_count) > 1" @click="$router.push('/verify')">
                <img src="~assets/images/header/chose-circle.png" alt="" class="password-img" />
                <img src="~assets/images/header/chose-circle-a.png" alt="" class="password-img-h" />
                <p>切换{{ field }}</p>
              </div>
              <div class="set-btn-item quit vertical-center" @click="quit">
                <img src="~assets/images/header/quit-icon.png" alt="" class="quit-img" />
                <img src="~assets/images/header/quit-icon-h.png" alt="" class="quit-img-h" />
                <p>退出登录</p>
              </div>
            </div>
          </div>
          <div class="occupied-box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import wx from 'weixin-js-sdk';
export default {
  name: 'Header',
  data() {
    return {
      field: '',
    };
  },
  methods: {
    // 用户信息
    userLink() {
      this.$router.push('/profile');
    },
    // 退出登录
    quit() {
      this.$cache.removeLoginInfo();
      this.$router.push('/login');
    },
    toPay() {
      this.$router.push({
        path: '/balance/Pay',
      });
    },
  },
  created() {
    this.field = process.env.VUE_APP_FIELD;
  },
  computed: {
    // field
    userInfo() {
      return this.$store.getters.userInfo;
    },
    serverCode() {
      return this.$store.getters.serverCode;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/header/header.less';
</style>
