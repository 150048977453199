export default {
    state: {
        userInfo: {},    // 用户信息
        serverCode: '', // 客服二维码
    },
    mutations: {
        M_USER_INFO(state, data) {
            state.userInfo= data
        },
        M_SERVER_CODE(state, data) {
            state.serverCode= data
        },
    },
    actions: {
        A_USER_INFO(context, data){
            context.commit('M_USER_INFO', data)
        },
        A_SERVER_CODE(context, data){
            context.commit('M_SERVER_CODE', data)
        },
    },
    getters: {
        userInfo: state => {
            return state.userInfo
        },
        serverCode: state => {
            return state.serverCode
        },

    }
}
