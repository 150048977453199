import axios from 'axios';
import cache from '../utils/cache'

export default {
    /*
    * get 请求方式
    * */
    get(url, author = false) {
        if (author) {
            return axios.get(url, {headers: cache.getAuthorization()});
        } else {
            return axios.get(url);
        }
    },

    /*
    * post 请求方式
    *
    * */
    post(url, data, author = false) {
        if (author) {
            return axios.post(url, data, {headers: cache.getAuthorization()})
        } else {
            return axios.post(url, data,)
        }
    },

    /**
     * put请求
     *
     */
    put(url, data, author = false) {
        if (author) {
            return axios.put(url, data, {headers: cache.getAuthorization()});
        } else {
            return axios.put(url, data);
        }
    },


    /**
     *
     * patch 请求方式
     *
     */
    patch(url, data, author = false) {
        if (author) {
            return axios.patch(url, data, {headers: cache.getAuthorization()});
        } else {
            return axios.patch(url, data);
        }
    },

    /**
     * delete 删除
     *
     */

    del(url, author = false) {
        if (author) {
            return axios.delete(url, {headers: cache.getAuthorization()});
        } else {
            return axios.delete(url);
        }
    },

    /**
     *  uploader上传文件
     *
     *
     */
    uploader(url, file, type, author = false) {
        let param = new FormData();
        param.append('file', file)
        if (author) {
            return axios.post(url, param, {headers: cache.getAuthorization()})
        } else {
            return axios.post(url, param)
        }
    }
}
