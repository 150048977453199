export default [
  // 标签管理
  {
    path: '/tag',
    name: 'tag',
    component: () => import('view/content/tag/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '标签管理',
      userInfo: false,
    },
  },
  // 课程管理 courses
  {
    path: '/courses',
    name: 'courses',
    component: () => import('view/content/courses/Index'),
    redirect: '/courses/courses',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '课程管理',
      userInfo: false,
    },
    children: [
      {
        path: 'courses',
        name: 'courses.courses',
        component: () => import('view/content/courses/Courses'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '课程管理',
        },
      },
      // 新增课程
      {
        path: 'create',
        name: 'courses.create',
        component: () => import('view/content/courses/Create'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '新增课程',
        },
      },
      // 新增课程
      {
        path: 'edit/:uuid',
        name: 'courses.edit',
        component: () => import('view/content/courses/Edit'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '编辑课程',
        },
      },
      // 查看课程
      {
        path: 'view/:uuid',
        name: 'courses.view',
        component: () => import('view/content/courses/View'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '查看课程',
        },
      },
      // 课时管理  classHour
      {
        path: 'class/:uuid',
        name: 'classHour',
        component: () => import('view/content/courses/classHour/Index'),
        redirect: '/courses/class/:uuid/chapters',
        children: [
          // 课时管理
          {
            path: 'chapters',
            name: 'courses.chapters',
            component: () => import('view/content/courses/classHour/Chapters'),
            meta: {
              Auth: true,
              header: true,
              nav: true,
              title: '课时管理',
            },
          },
          // 课程媒体
          {
            path: 'media',
            name: 'courses.media',
            component: () => import('view/content/courses/classHour/Media'),
            meta: {
              Auth: true,
              header: true,
              nav: true,
              title: '课时媒体',
            },
          },
          // 资料管理
          {
            path: 'materials',
            name: 'courses.materials',
            component: () => import('view/content/courses/classHour/Materials'),
            meta: {
              Auth: true,
              header: true,
              nav: true,
              title: '资料管理',
            },
          },
          // 互动
          {
            path: 'interact',
            name: 'courses.interact',
            component: () => import('view/content/courses/classHour/Interact'),
            meta: {
              Auth: true,
              header: true,
              nav: true,
              title: '互动',
            },
          },
          // 学员管理
          {
            path: 'Student',
            name: 'courses.student',
            component: () => import('view/content/courses/classHour/Student'),
            meta: {
              Auth: true,
              header: true,
              nav: true,
              title: '学员管理',
            },
          },
        ],
      },
    ],
  },
  // 文章管理 article
  {
    path: '/article/text',
    name: 'text',
    component: () => import('view/content/article/Text'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '新增',
    },
  },
  {
    path: '/article/video',
    name: 'texts',
    component: () => import('view/content/article/Video'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '新增',
    },
  },
  {
    path: '/creation',
    name: 'creation',
    component: () => import('view/content/article/Index'),
    redirect: '/creation/creation',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '文章/视频管理',
      userInfo: false,
    },
    children: [
      {
        path: 'creation',
        name: 'creation.creation',
        component: () => import('view/content/article/List'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '文章/视频管理',
        },
      },
      // {
      //   path: 'text',
      //   name: 'article.text',
      //   component: () => import('view/content/article/Text'),
      //   meta: {
      //     Auth: true,
      //     header: true,
      //     nav: true,
      //     title: '新增',
      //   },
      // },
      // {
      //   path: 'video',
      //   name: 'article.video',
      //   component: () => import('view/content/article/Video'),
      //   meta: {
      //     Auth: true,
      //     header: true,
      //     nav: true,
      //     title: '新增',
      //   },
      // },
      {
        path: 'materials',
        name: 'creation.materials',
        component: () => import('view/content/article/Materials'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '文章资料',
        },
      },
    ],
  },
  // 评价管理
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('view/content/evaluate/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '评价管理',
      userInfo: false,
    },
  },
  // 回播管理
  {
    path: '/replay',
    name: 'replay',
    component: () => import('view/content/replay/Index'),
    redirect: '/replay/List',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '回播管理',
      userInfo: false,
    },
    children: [
      {
        path: 'list',
        name: 'replay.list',
        component: () => import('view/content/replay/List'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '文章/视频管理',
        },
      },
      {
        path: 'info',
        name: 'replay.info',
        component: () => import('view/content/replay/Info'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '新增',
        },
      },
    ],
  },
  // 素材管理-图库
  {
    path: '/source',
    name: 'source',
    component: () => import('view/content/source/Index'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '图库管理',
      userInfo: false,
    },
  },
  // 素材管理-资料
  {
    path: '/means',
    name: 'means',
    component: () => import('view/content/source/Means'),
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '资料管理',
      userInfo: false,
    },
  },
  // 我的文件
  // {
  //   path: '/files',
  //   name: 'files',
  //   component: () => import('view/content/files/Index'),
  //   redirect: '/files/files',
  //   meta: {
  //     Auth: true,
  //     header: true,
  //     nav: true,
  //     title: '我的文件',
  //     userInfo: false,
  //   },
  //   children: [
  //     {
  //       path: 'files',
  //       name: 'files.files',
  //       component: () => import('view/content/files/views/Index'),
  //       meta: {
  //         Auth: true,
  //         header: true,
  //         nav: true,
  //         title: '我的文件',
  //       },
  //     },
  //   ],
  // },
  // 文件管理
  {
    path: '/filemanagement',
    name: 'filemanagement',
    component: () => import('view/content/fileManagement/Index'),
    redirect: '/filemanagement/filemanagement',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '文件管理',
      userInfo: false,
    },
    children: [
      {
        path: 'filemanagement',
        name: 'filemanagement.filemanagement',
        component: () => import('view/content/fileManagement/views/Index'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '文件管理',
        },
      },
    ],
  },
];
