import router from '@/router/index';
import cache from './cache';
import Api from '../http/api';
import http from '../http/request';
export default function() {
  let mu = 0;
  // 获取配置信息
  http.get(Api.loginConfigs, true).then((res) => {
    if (res.data.success) {
      let info = res.data.data;
      mu = Number(info.minute);
      let setInterval = null; //定时器
      let timeOut = mu * 60 * 1000; // 设置超时时间
      // 初次向sessionStorage存入操作时间
      window.sessionStorage.setItem('lastTime', new Date().getTime());
      // 每次操作页面，更新sessionStorage存入的操作时间
      window.onload = function() {
        mouse();
        key();
      };
      function mouse() {
        window.document.onmousedown = function() {
          //console.log('鼠标操作页面!!!!!!!!!!!!!!!!!!!!!');
          window.sessionStorage.setItem('lastTime', new Date().getTime());
        };
      }
      function key() {
        window.document.onkeydown = function() {
          //console.log('键盘操作页面!!!!!!!!!!!!!!!!!!!!!');
          window.sessionStorage.setItem('lastTime', new Date().getTime());
        };
      }
      function checkTimeout() {
        //console.log('检查中');
        let currentTime = new Date().getTime(); // 当前时间
        let lastTime = window.sessionStorage.getItem('lastTime'); //上次操作的时间
        // 判断是否超时
        if (currentTime - lastTime >= timeOut) {
          // 清除定时器
          window.clearInterval(setInterval);
          // 清除sessionStorage
          window.sessionStorage.clear('lastTime');
          // 跳到登陆页
          if (router.history.current.name == 'login') return; // 当前已经是登陆页时不做跳转
          cache.removeLoginInfo();
          router.replace({ path: '/login' });
        }
      }
      /* 定时器 间隔30秒检测是否长时间未操作页面 */
      setInterval = window.setInterval(checkTimeout, 30000);
    }
  });
}
