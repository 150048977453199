module.exports = {
  Users: 'users', // 用户
  Account: 'account', // 账户
  accountLogs: 'account_logs', // 记录日志
  changeAccount: 'change_account', // 更新金币
  userCourses: 'user/courses', // 用户课程
  changeIdentity: 'change_identity', // 设置身份
  changePresenter: 'change_presenter', // 主播设置
  reviewedPresenter: 'reviewed_presenter', //审核
  removeCircle: 'remove_circle', //移出小组
  setAuthor: 'circles/set_author', //设置作者
  circles: 'circles', //创作者审核
  circlesExamine: 'examine_author', //创作者审核
  // 余额记录
  circlesAccountLogs: 'circles/account/logs',
  // 充值配置
  rechargeConfigs: 'recharge/configs',
  // 充值创建订单
  rechargeCreateOrder: 'recharge/create_order',
  // 支付
  rechargeScanPay: 'recharge/scan_pay',
  // 支付状态查询
  rechargePayResult: 'recharge/pay_result',
};
