export default [
  // 课程管理 courses
  {
    path: '/meeting',
    name: 'meeting',
    component: () => import('view/meeting/Index'),
    redirect: '/meeting/meeting',
    meta: {
      Auth: true,
      header: true,
      nav: true,
      title: '会议直播',
      userInfo: false,
    },
    children: [
      {
        path: 'meeting',
        name: 'meeting.meeting',
        component: () => import('view/meeting/meeting/Index'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '会议直播',
        },
      },
      {
        path: 'show/:uuid',
        name: 'meeting.show',
        component: () => import('view/meeting/meeting/show'),
        meta: {
          Auth: true,
          header: true,
          nav: true,
          title: '直播详情',
        },
      },
      {
        path: 'components/:uuid',
        name: 'meeting.components',
        component: () => import('view/meeting/commentPages/Index'),
        meta: {
          Auth: false,
          header: false,
          nav: false,
          share: true,
          title: '评论',
        },
      },
    ],
  },
];
