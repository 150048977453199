module.exports = {
  meeting: 'meeting', // 直播列表  新增/修改直播
  cancel: 'cancel', // 取消直播
  show: 'show', // 隐藏/显示直播
  overview: 'meeting/live/overview', // 数据总览
  conference: 'meeting/live/conference', // 数据列表
  dayDate: 'meeting/live/date', // 分日列表
  dayDateDetail: 'meeting/date/detail', // 分日列表详情
  detailUser: 'meeting/detail/user', // 回放观看人员统计
  liveExport: 'meeting/live/export', // 回放观看人员统计
  news: 'news', // 评论
  playback: 'playback', // 回放列表编辑
  address: 'address', // 回放列表地址
  showType: 'show', // 回放状态修改
  startLive: 'start_live', // 开始直播
  stopLive: 'stop_live', // 结束直播
};
