<template>
  <div class="circles-nav-wrap" :class="adminMiddleground ? 'circles-nav-wrap' : 'circles-nav-wrap-height'">
    <div class="circles-nav-item">
      <p class="circles-nav-tit">工作</p>
      <div class="nav-line"></div>
      <router-link tag="div" to="/home" exact class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/home.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/home-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">首页</p>
      </router-link>
      <router-link tag="div" to="/profile" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/task.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/task-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">我的资料</p>
      </router-link>
      <router-link tag="div" to="/rule" class="circles-nav-list vertical-center" v-if="adminMiddleground">
        <img src="~assets/images/nav/rule.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/rule-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">规则配置</p>
      </router-link>
    </div>
    <div class="circles-nav-item" v-if="adminMiddleground">
      <p class="circles-nav-tit">内容</p>
      <div class="nav-line"></div>
      <router-link tag="div" to="/tag" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/tag.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/tag-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">标签管理</p>
      </router-link>
      <router-link tag="div" to="/courses" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/courses.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/courses-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">课程管理</p>
      </router-link>
      <router-link tag="div" to="/replay" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/live.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/live-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">回播管理</p>
      </router-link>
      <router-link tag="div" to="/meeting" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/meeting.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/meeting-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">会议直播</p>
      </router-link>
      <router-link tag="div" to="/creation" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/article.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/article-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">文章/视频管理</p>
      </router-link>
      <router-link tag="div" to="/evaluate" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/comment.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/comment-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">评价管理</p>
      </router-link>
      <router-link tag="div" to="/source" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/material.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/material-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">素材管理</p>
      </router-link>
      <router-link tag="div" to="/filemanagement" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/files.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/files-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">文件管理</p>
      </router-link>
    </div>
    <div class="circles-nav-item" v-if="!adminMiddleground">
      <p class="circles-nav-tit">创作</p>
      <div class="nav-line"></div>
      <router-link tag="div" to="/article/text" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/posts.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/posts_active.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">发布文章</p>
      </router-link>
      <router-link tag="div" to="/article/video" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/video.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/active_video.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">上传视频</p>
      </router-link>
      <router-link tag="div" to="/creation" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/creation.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/creation_active.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">创作管理</p>
      </router-link>
    </div>
    <div class="circles-nav-item" v-if="!adminMiddleground">
      <!-- <div class="circles-nav-item"> -->
      <p class="circles-nav-tit">管理</p>
      <div class="nav-line"></div>
      <router-link tag="div" to="/filemanagement" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/files.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/files-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">课程文件</p>
      </router-link>
      <router-link tag="div" to="/evaluate" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/comment.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/comment-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">评价管理</p>
      </router-link>
    </div>
    <div class="circles-nav-item" v-if="adminMiddleground">
      <p class="circles-nav-tit">数据</p>
      <div class="nav-line"></div>
      <!-- <router-link tag="div" to="/analysis/user" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/analysis-user.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/analysis-user-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">用户分析</p>
      </router-link> -->
      <router-link tag="div" to="/analysis/course" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/course.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/course-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">课程分析</p>
      </router-link>
      <router-link tag="div" to="/analysis/content" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/content.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/content-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">内容分析</p>
      </router-link>
      <router-link tag="div" to="/analysis/live" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/lives.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/lives-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">直播分析</p>
      </router-link>
      <!-- <router-link tag="div" to="/analysis/gold" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/gold.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/gold-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">金币分析</p>
      </router-link> -->
    </div>
    <div class="circles-nav-item" v-if="adminMiddleground">
      <p class="circles-nav-tit">用户</p>
      <div class="nav-line"></div>
      <router-link tag="div" to="/user" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/user.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/user-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">用户管理</p>
      </router-link>
      <router-link tag="div" to="/balance" class="circles-nav-list vertical-center">
        <img src="~assets/images/nav/balance.png" class="nav-icon" alt="" />
        <img src="~assets/images/nav/balance-act.png" class="nav-icon nav-icon-link" alt="" />
        <p class="nav-tit">我的余额</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'circlesNav',
  data() {
    return {
      adminMiddleground: false,
      circleAuthor: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    userInfo(newValue) {
      let data = newValue.managed_circle;
      if (this.userInfo && data.is_admin && data.is_author == 'success') {
        this.adminMiddleground = this.userInfo.is_admin_middleground;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/nav/circles-nav.less';
</style>
