export default {
  /*
   * 生成随机字符串
   * */
  randomString(len) {
    len = len || 32;
    let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  },
  /*
   * 获取文件格式后缀
   * */
  suffixFun(FileName) {
    var index1 = FileName.lastIndexOf('.');
    var index2 = FileName.length;
    var suffix = FileName.substring(index1, index2); //后缀名
    return suffix;
  },
  /*
   * 判断时候是手机端
   * */
  browserRedirect() {
    let status;
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      // 手机
      status = true;
    } else {
      // pc
      status = false;
    }
    return status;
  },
  /*
   * 站点跳转
   * */
  portLink() {
    if (window.location.href.indexOf('www.') != -1) {
      window.location.href = window.location.href.replace('www.', 'm.');
    }
  },

  /*
   * 下载文件
   * */
  async downloadRes(url, name) {
    const src = `${url}?t=${new Date().getTime()}`;
    fetch(src).then((res) => {
      res.blob().then((myBlob) => {
        const href = URL.createObjectURL(myBlob);
        const a = document.createElement('a');
        a.href = href;
        a.download = name;
        a.click();
        a.remove();
      });
    });
  },

  /*
   * 下载文件流
   * */
  exportFile(file, name) {
    const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
    const elink = document.createElement('a');
    elink.download = name;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  },
};
