import Vue from 'vue';
import Router from 'vue-router';
import Auth from './module/auth';
import User from './module/user';
import Analysis from './module/analysis';
import Content from './module/content';
import Home from './module/home';
import Share from './module/share';
import Meeting from './module/meeting';

Vue.use(Router);
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
var constantRoutes = new Set([...Home, ...Auth, ...User, ...Analysis, ...Content, ...Share, ...Meeting]);
export default new Router({
  mode: 'history',
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      //keepAlive 配置缓存后的组件不处理回到顶部问题
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

// vue重复点击路由报错
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
